import css from "./HeaderNumberTextLayout.module.scss";
import { ColorOptions } from "./../../Elements/ColorOptions/ColorOptions.jsx";
import { BackgroundColorOptions } from "./../../Elements/BackgroundColorOptions/BackgroundColorOptions.jsx";

export const HeaderNumberTextLayout = ({
    fullWidth,
    repeater,
    backgroundColor
}) => {

  const backgroundOptionColor = BackgroundColorOptions({
    optionColor: backgroundColor?.colors,
  });

  return (
    <div className={`${css.headerNumberTextWrapper} ${backgroundOptionColor} ${fullWidth?.fullWidth === true ? "full" : "wrapped"}`}>

        <div className={`${fullWidth?.fullWidth === true ? "ifFullWidthContentWrapper" : ""} ${css.blocksWrapper}`}>

            {repeater?.map((item, index) => (

                <div
                    className={`
                        ${css.block}
                    `}
                    key={`item-${index}`}
                >

                <h3
                    className={`
                        ${css.header}

                        ${ColorOptions({ optionColor: item?.colorTitle?.colors })}
                    `}
                >
                    {item?.header}
                </h3>

                <p
                    className={`
                        ${css.number}
                        ${ColorOptions({ optionColor: item?.numberColor?.colors })}
                        h1
                        large
                    `}
                >
                    {item?.number}
                </p>
                <div
                    className={`
                        ${css.text}
                    `}
                    dangerouslySetInnerHTML={{ __html: item?.html }}
                />
              </div>
            ))}

          </div>
      </div>
  );
};
